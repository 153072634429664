export default (integrations) => {
  const {
    facebookPixel: { pixelId: fbPixelId },
    googleAnalytics: { trackingId: gaTrackingId },
    googleAnalytics4: { trackingId: ga4TrackingId },
    matomoAnalytics: { id: matomoId, url: matomoUrl },
  } = integrations;

  const script = [];

  /**
   * Matomo Analytics
   */
  if (matomoId && matomoUrl) {
    script.push({
      hid: "matomo-analytics",
      innerHTML: `var _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="${matomoUrl}";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '${matomoId}']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();`,
      type: "text/javascript",
      charset: "utf-8",
    });
  }

  /**
   * Facebook Pixel
   */
  if (fbPixelId) {
    script.push({
      hid: "facebook-pixel",
      innerHTML: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${fbPixelId}');
      fbq('track', 'PageView');`,
      type: "text/javascript",
      charset: "utf-8",
    });
  }

  /**
   * Google Analytics
   */
  if (gaTrackingId) {
    script.push({
      hid: "google-analytics",
      innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        
        ga('create', '${gaTrackingId}', 'auto');
        ga('send', 'pageview');`,
      type: "text/javascript",
      charset: "utf-8",
    });
  }

  /**
   * Google Analytics 4
   */
  if (ga4TrackingId) {
    script.push({
      src: `https://www.googletagmanager.com/gtag/js?id=${ga4TrackingId}`,
      async: true,
      hid: "google-analytics-4-script",
      type: "text/javascript",
      charset: "utf-8",
    });

    script.push({
      hid: "google-analytics-4",
      innerHTML: `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${ga4TrackingId}');`,
      type: "text/javascript",
      charset: "utf-8",
    });
  }

  return {
    script,
  };
};

export default ({ displayFont, bodyFont }) => {
  return {
    hid: "google-fonts",
    innerHTML: `
    WebFontConfig = {
      google: {
        families: ["${displayFont}:400,700", "${bodyFont}:400,700"]
      }
    };
    
    (function(d) {
      var wf = d.createElement('script'), s = d.scripts[0];
      wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
      wf.async = true;
      s.parentNode.insertBefore(wf, s);
    })(document);
    `,
    type: "text/javascript",
    charset: "utf-8",
  };
};

export default (customCodes) => {
  const customCode = {
    styles: [],
    scripts: [],
  };

  for (const code of customCodes) {
    const { name, language, content, position } = code;
    /**
     * position example: body_top, head_top, body_bottom, head_bottom
     * splitting the position with "_": ["body", "top"] etc.
     */
    const splittedPosition = position.split("_");
    const isBody = splittedPosition[0] === "body";
    const isTop = splittedPosition[1] === "top";
    switch (language) {
      case "js":
        customCode.scripts.push({
          hid: name,
          innerHTML: content,
          body: isBody && !isTop,
          pbody: isBody && isTop,
          type: "text/javascript",
          charset: "utf-8",
        });
        break;

      case "css":
        customCode.styles.push({
          hid: name,
          children: content,
          body: isBody && !isTop,
          pbody: isBody && isTop,
          type: "text/css",
        });
        break;
    }
  }

  return customCode;
};

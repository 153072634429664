export default ({ primaryColor, secondaryColor, displayFont, bodyFont }) => {
  let css = ":root { ";
  for (const [key, value] of Object.entries(primaryColor)) {
    css += `--primary-${key}: ${value}; `;
  }
  for (const [key, value] of Object.entries(secondaryColor)) {
    css += `--secondary-${key}: ${value}; `;
  }
  css += `--font-display: ${displayFont}; --font-body: ${bodyFont} }`;

  return {
    children: css,
    type: "text/css",
  };
};

import { useBizStore } from "@/store/index.js";
import integrations from "@/lib/web-integrations";
import customCodes from "@/lib/custom-codes";
import googleFonts from "@/lib/google-fonts";
import theme from "@/lib/theme";

export const useHeadAndMeta = () => {
  const bizStore = useBizStore();
  const storeTheme = computed(() => bizStore.theme);
  const name = computed(() => bizStore.name);
  const subdomain = computed(() => bizStore.subdomain);
  const desc = computed(() => bizStore.desc);
  const logo = computed(() => bizStore.logo);
  const address = computed(() => bizStore.address);
  const countryCode = computed(() => bizStore.countryCode);
  const url = computed(() => bizStore.url);
  const mobiles = computed(() => bizStore.mobiles);
  const webIntegrations = computed(() => bizStore.webIntegrations);
  const storeCustomCodes = computed(() => bizStore.customCodes);

  const items = {
    title: name.value,
    titleTemplate: (title) => {
      if (title === name.value) {
        return title;
      } else {
        return title ? `${title} - ${name.value}` : name.value;
      }
    },
    meta: [
      { charset: "utf-8" },
      {
        hid: "og:url",
        property: "og:url",
        content: url.value,
      },
      {
        hid: "og:type",
        property: "og:type",
        content: "website",
      },
      {
        hid: "description",
        name: "description",
        content: desc.value,
      },
      {
        hid: "og:title",
        property: "og:title",
        content: name.value,
      },
      {
        hid: "og:description",
        property: "og:description",
        content: desc.value || "",
      },
      {
        hid: "og:image",
        name: "og:image",
        content: logo.value || "",
      },
    ],
    link: [
      { rel: "manifest", href: `/api/${subdomain.value}/manifest.json` },
      { rel: "dns-prefetch", href: "https://vepaar.b-cdn.net" },
      { rel: "dns-prefetch", href: "https://vepaar.store" },
      { rel: "dns-prefetch", href: "https://api.vepaar.com" },
      { rel: "preconnect", href: "https://vepaar.b-cdn.net" },
      { rel: "preconnect", href: "https://fonts.googleapis.com" },
      { rel: "preconnect", href: "https://vepaar.store" },
      { rel: "preconnect", href: "https://api.vepaar.com" },
      {
        rel: "preconnect",
        href: "https://fonts.gstatic.com",
        crossorigin: true,
      },
      {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: logo.value || "/favicon.png",
      },
    ],

    script: [
      googleFonts(storeTheme.value),
      {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Store",
          name: name.value,
          url: url.value,
          description: desc.value,
          address: {
            "@type": "PostalAddress",
            streetAddress: address.value,
            addressCountry: countryCode.value,
          },
          contactPoint: mobiles.value.map((mobile) => {
            return {
              "@type": "ContactPoint",
              contactType: mobile.designation,
              telephone: mobile.mobileNumber.internationalFormat,
            };
          }),
        }),
      },
    ],

    style: [theme(storeTheme.value)],
  };

  /**
   * Web Integrations
   */
  const { script } = integrations(webIntegrations.value);
  items.script.push(...script);

  const { scripts, styles } = customCodes(storeCustomCodes.value);
  items.script.push(...scripts);
  items.style.push(...styles);

  return items;
};
